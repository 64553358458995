.sptfaq {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    grid-column-gap: 5rem;

    padding: 5rem;
    margin: 5rem 0;

    @include respond(tab-land) {
        
    }

    @include respond(phone-large) {
        padding: 5rem 2rem;
    }

    &__key {
        flex: 1;
        position: sticky;
        top: 8rem;
        left: 1rem;
        margin-top: 6rem;
        background-color: $color-grey-light-1;
        padding: 2rem;

        @include respond(tab-land) {
            display: none;
            width: 0;
        }
        
        &__header {
            font-size: 1.8rem;
            margin-bottom: 2rem;
        }

        ul {
            list-style: none;

            li {
                font-size: $default-font-size;
                padding: 1rem 0;
            }
        }
    }

    &__faq {
        flex: 4;
        

        &__item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 10rem;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__head {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            
            &__imgbox {
                padding: 2rem;
                border: 3rem;
                background-color: $color-primary-light;
                border-radius: 3rem;
                margin-right: 2.5rem;
            }   

            &__img {
                width: 2.5rem;
                height: auto;
                color: $color-primary-dark;
            }

            &__title {
                font-size: 3rem;
            }
        }

        &__section {
            width: 100%;

            li {
                display: flex;
                flex-direction: column;
                padding: 3rem 0;
                border-bottom: 1px solid $color-grey-dark;
                cursor: pointer;
                
                

                &:hover > .sptfaq__faq__section__text {
                    display: flex;
                    width: 60%;
                }

                &:last-child {
                    border-bottom: none;
                }
            }

            &__head {
                display: flex;
                justify-content: space-between;

                p {
                    font-weight: bold;
                }

                &__add {
                    width: 2.5rem;
                    height: auto;
                    color: $color-primary-dark;
                }
                
            }

            &__text {
                display: none;
                width: 0;
                margin-top: 3rem;

                animation: drop .3s ease;

                

            }
        }
    }
}