.ftrcards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 6rem;

    &__top {
        width: 60%;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 8rem;

        @include respond(tab-land) {
            width: 75%;
        }

        @include respond(phone-large) {
            width: 80vw;
        }
    }

    &__container {
        
        display: flex;
        justify-content: center;
        align-items: center;

        @include respond(tab-land) {
            flex-direction: column;
        }

        @include respond(phone-large) {
            width: 85vw;
        }

    }



    &__card {
        margin-right: 6rem;
        flex: 1;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem 0;

        @include respond(tab-land) {
            margin-right: 0;
        }

        &__header {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 4rem;

            &__name {
                font-size: 2rem;
                margin-right: 2rem;
            }

            &__tag {
                display: none;
                width: 0;

                padding: 1rem;
                background-color: rgba($color-primary-dark, 0.5);
                color: $color-primary-dark;
                font-size: 1.5rem;
                font-weight: bold;
            }

        }

        &__plan {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            margin: 2rem 0;

            &__name {
                font-size: 5rem;


            }

            span {
                color: $color-grey-dark;

            }
        }

        &__description {
            text-align: center;
            width: 80%;
            margin-bottom: 4rem;
            color: $color-grey-dark;
        }

        &__imgbox {
            margin-bottom: 1rem;

        }

        &__img {
            width: 100%;
            height: auto;
        }

        &__btn {
            width: 100%;
            background-color: $color-primary-dark;
            border-radius: 1rem;

        }

    }

    // Can Use grid-column-gap to space the items but lets try this
    &:last-child {
        margin-right: 0;
    }


}