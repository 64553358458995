.cardtable {
    padding: 3rem 3rem;
    margin-bottom: 6rem;

    table, tr {
        border-collapse: collapse;
      }

    &__table {
        table-layout: fixed;
        width: 100%;

        @include respond(tab-land) {
            display: none;
            width: 0;
        }

        tr {
            height: 12rem;
            border-bottom: 1px solid $color-grey-dark;
            
        }

        td {
            height: 12rem;
            width: 25%;
            text-align: center;
            vertical-align: middle;
        }

        &__imglabelbox {
            border-bottom: none !important;
        }
        
    }

    &__noborderbottom {
        border-bottom: none !important;
    }

    &__sectionhead {
        font-weight: 700;
        
    }

    &__checkmark {
        color: $color-primary-dark;
        width: 2rem;
        height: 2rem;
    }

    &__label {

        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include respond(tab-land) {
            flex-direction: column;
            justify-content: center;
        }
        

        &__imgbox {
            padding: 2rem;
            background-color: $color-primary-light;
            border-radius: 5rem;
            margin-right: 2.5rem;
        }

        &__textbox {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;

            @include respond(tab-land) {
                
                align-items: center;
                padding-top: 2rem;
                padding-bottom: 1rem;
            }

        }

        &__img {
            width: 3rem;
            height: 3rem;
            color: $color-primary-dark;
        }

        &__title {
            font-size: 1.8rem;
            font-weight: 500;

            @include respond(tab-land) {
                margin-bottom: 1rem;
            }
        }

        &__description {
            color: $color-grey-dark;
            font-size: 1.3rem;

        }
    }

    &__phone {

        display: none;
        width: 0;

        @include respond(tab-land) {
            width: 100%;
            display: flex;
            flex-direction: column;

        }

        &__tier {

            &__subheader {
                width: 100%;
                text-align: center;
                font-weight: 600;
                padding: 2rem 0;
                border-bottom: 1px solid #ddd;

            }


            &__item {
                // margin-bottom: 2rem;
                border-bottom: 1px solid #ddd;
                padding: 3rem 0;

                &__value {
                    width: 100%;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 2rem 0;
                }
            }
        }
    }
}