.integrate {
    margin-top: 8rem;
    width: 98vw;

    @include respond(tab-land) {
       display: flex;
       justify-content: center;
       align-items: center;
       flex-direction: column;
    }

    &__partners {

        margin-bottom: 4rem;

        display: flex;
        flex-wrap: wrap;
        width: 65%;

        padding: $body-padding-large;

        @include respond(tab-land) {
            width: 90%;
            padding: $body-padding-medium;
        }

        @include respond(phone-large) {
            width: 95%;
            padding: $body-padding-small;
        }

        @include respond(phone-medium) {
            width: 99%;
            padding: $body-padding-xtra-small;
        }

        &__item {
            margin-right: 2rem;

            &__logo {}

        }



    }

    &__tools {

        display: flex;
        justify-content: center;
        align-items: center;

        @include respond(tab-land) {
            flex-direction: column;
        }
        

        &__content {
            flex: 1;
            padding: $body-padding-large;

            @include respond(tab-land) {
                padding: $body-padding-medium;
            }

            @include respond(phone-large) {
                padding: $body-padding-small;
            }

            @include respond(phone-small) {
                padding: $body-padding-xtra-small;
            }
        }

        &__tophead {
            font-size: 1.6rem;
            padding-bottom: 2rem;
            
        }

        &__header {
            font-size: $header-big;
            margin-bottom: 2rem;
            width: 80%;

           

            @include respond(phone-large) {
                font-size: $header-medium;
            }


        }

        &__text {
            display: flex;
            justify-content: space-between;
            padding-bottom: 2rem;


        }

        &__list {
            list-style: none;
            flex : 1;

            @include respond(tab-land) {
                padding: 2rem 4rem;
            }

            &__item {
                margin-bottom: 1rem;
    
                display: flex;
                // justify-content: center;
                align-items: center;
    
                &__label {
                    width: 3rem;
                    height: 3rem;
                    color: $color-primary-dark;
                    margin-right: 1.5rem;
                
                }
    
                span {
    
                }
    
            }

            


        }

    }
}