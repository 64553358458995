.mychatbot {
    position: fixed;
    right: 3rem;
    bottom: 3rem;
    z-index: 99;

    &__label {
        width: 5rem;
        height: 5rem;
        cursor: pointer;

        
    }
}