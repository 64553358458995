.accounts {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 6rem;

    &__tophead {
        font-size: 1.6rem;
        padding-bottom: 2rem;
    }

    &__header {

        font-size: $header-big;
        margin-bottom: 2rem;
        width: 45%;

        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        @include respond(tab-land) {
            width: 75%;
        }

        @include respond(phone-large) {
            font-size: $header-medium;
        }

    }

    &__textbox {
        display: flex;
        width: 40%;

        
        padding-bottom: 2rem;

        @include respond(tab-land) {
            width: 80%;
            text-align: center
            
        }

    }


    &__cardcontainer {

        @include clearfix;

        padding: 2rem 2rem;
        position: relative;
       
        padding-top: 60rem;

        @include respond(phone-large) {
            padding-top: 30rem;
        }


    }

    &__card {
        position: absolute;
        top:50%;
        left: 50%;
        // transform: translate(-50%, -50%);


        &__img {

            @include respond(tab-land) {
                width: 40rem;
                height: auto;
            }

            @include respond(phone-large) {
                width: 28rem;
                height: auto;
            }


        }

        &--1 {
            transform: translate(-50%, -80%);
        }

        &--2 {
            transform: translate(-50%, -50%);
        }

        &--3 {
            transform: translate(-50%, -20%);
            // transform: translate(-50%, -50%) rotate(-90deg);
            
        }
    }
    
    &__btnsection {
        width: 100%;
        display: flex;
        justify-content: center;

        @include respond(tab-land) {
            flex-direction: column;

            align-items: center;
        }

    }

    &__btn {
        

        &--open {
            background-color: $color-primary-dark;
            color: $color-white;
            margin-right: 3rem;
            border-radius: 1rem;

            @include respond(tab-land) {
                width: 50%;
                margin-bottom: 2.5rem;
                margin-right: 0;
            }
        }

        &--compare {
            color: $color-black;
            border-radius: 1rem;

            @include respond(tab-land) {
                width: 50%;
            }
        }
        

    }
}