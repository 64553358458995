.frozenmodal {

    &__container {
        
    }


    padding: 3rem;
    display: flex;
    height: 100%;
    justify-content: space-around;
    flex-direction: column;

    

    h2 {

    }

    p {

    }
}