.help {
    display: flex;
    justify-content: center;
    // align-items: center;
    margin-bottom: 6rem;

    @include respond(tab-land) {
        flex-direction: column;
        align-items: center;
    }

    &__content {
        flex: 1;

        padding: 5rem 2rem 5rem 10rem;

        @include respond(tab-land) {
            padding: $body-padding-medium;
        }

        @include respond(phone-large) {
            padding: $body-padding-small;
        }

        @include respond(phone-medium) {
            padding: $body-padding-xtra-small;
        }


        &__header {
            font-size: 6rem;
            margin-bottom: 4rem;
            width: 80%;
            
            @include respond(tab-land) {
                width: 100%;
            }

            @include respond(phone-large) {
                font-size: $header-medium;
            }
        }

        &__item {

            display: flex;
            // justify-content: center;
            align-items: center;
            margin-bottom: 3rem;

            &__label {
                width: 5rem;
                height: 5rem;

                border-radius: 2.5rem;

                background-color: rgba($color-grey-dark, 0.2);

                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 2rem;

            }

            &__img {

                width: 3rem;
                height: 3rem;
                color: $color-primary-dark;

            }

            &__textbox {}

            &__main {
                font-weight: 700;
                margin-bottom: 1rem;

            }

            &__sub {
                color: $color-grey-dark;

            }
        }

        &__btnsection {
            display: flex;
            // justify-content: center;
            align-items: center;

        }

        &__btn {

            color: $color-primary-dark;
            background-color: transparent;
            border-radius: 0;
            font-weight: 700;
            font-size: 1.8rem;
        }

    }

    &__faq {
        flex: 1;
        padding: 5rem 5rem 5rem 2rem;

        @include respond(tab-land) {
            padding: $body-padding-medium;
        }

        @include respond(phone-large) {
            padding: $body-padding-small;
        }

        @include respond(phone-medium) {
            padding: $body-padding-xtra-small;
        }


        &__container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

        }

        &__item {
            // padding: 2rem;
            width: 90%;
            border-bottom: 1px solid rgba($color-grey-dark, 1);

            &__titlebox {
                display: flex;
                justify-content: space-between;
                align-items: center;
                
                padding: 2rem 0;
            }

            &__title {
                font-size: 1.8rem;
                font-weight: 600;
            }

            &__icon {
                width: 2rem;
                height: 2rem;
                color: $color-primary-dark;

            }

            &__content {

                padding: 2rem 0;
                display: none;
                width: 0;

            }

            &:hover > &__content {
                display: block;
                width: auto;
            }
        }



    }
}