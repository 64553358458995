.login {

    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $default-font-size;
    min-height: 100vh;
    background-image: url(../img/viktor-forgacs-q8XSCZYh6D8-unsplash.jpg);
    background-size: cover;
    padding-top: 8rem;

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

    }

    &__formbox {
        padding: 5rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;

        background-color: rgba($color-grey-light-1, 0.8);
        width: 65%;

        @include respond(tab-land) {
            width: 80%;
            padding: 3rem;
            align-items: center;
        }

        @include respond(phone-large) {
            padding: 2rem;
            width: 90%;
        }

    }

    &__form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
        
        @include respond(tab-land) {
            align-items: center;
        }
    }

    &__inputbox {
        margin: 2rem 0;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 70%;

        @include respond(tab-land) {
            width: 80%;
        }

        @include respond(phone-large) {
            width: 90%;
        }

        @include respond(phone-medium) {
            width: 95%;
        }
    }

    &__input {
        padding: 1.5rem;
        width: 100%;
        font-size: 1.5rem;
        

        &__icon {
            width: 2rem;
            color: white;

            &__box {
                padding: 1.5rem;
                background-color: $color-primary-dark;
            }
        }
    }

    &__imgbox {
        // position: absolute;

        margin-right: -20rem;
        z-index: 9;

        @include respond(tab-land) {
            margin-right: 0;
        }
        
    }

    &__img {
        width: 35rem;
        height: auto;

        @include respond(tab-land) {
            width: 0;
            height: 0;
            display: none;
        }

        @include respond(phone-large) {
            
        }

    }

    &__btnsection {
        display: flex;
        // flex-direction: column;
        margin-top: 2rem;

        @include respond(phone-large) {
            flex-direction: column;
        }
    }

    &__btn {
        margin-bottom: 2rem;
        border-radius: 1rem;

        &--log {
            background-color: $color-primary-dark;
            margin-right: 3rem;
            padding: 2rem 5rem;

            @include respond(tab-land) { 
                padding: 1.2rem 2.5rem;
            }

            @include respond(phone-large) {
                margin-right: 0;
            }
        }

        &--forgot {
            background-color: $color-secondary-light;
        }
    }
}