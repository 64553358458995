.home {

    font-size: 1.6rem;

    &__header {
        height: 90px;
        padding: 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.5rem;
        border-bottom: 1px solid $color-grey-light-2;


        &__logobox {}

        &__logo {}

        &__currentpage {
            
            display: flex;
            justify-content: center;
            align-items: center;

            font-size: 2.5rem;
            text-transform: uppercase;
            color: $color-secondary-dark;


            @include respond(phone-large) {
                font-size: 1.8rem;
            }
            
        }

        &__user {
            display: flex;
            justify-content: center;
            align-items: center;

            @include respond(tab-land) {
                display: none;
                width: 0;
            }

            &__welcome {
                margin-right: 2rem;

            }

            &__avatar {
                width: 4rem;
                height: 4rem;
                margin-right: 2rem;

            }

            &__logout {
                display: flex;
                cursor: pointer;

                &__icon {
                    width: 2rem;
                    height: 2rem;
                    margin-right: 5px;

                }

                p {}
            }

        }

        &__menu {

            display: none;
            width: 0;
    
    
            @include respond(tab-land) {
                display: flex;
                flex-direction: column;
                width: 30px;
            }
    
            &__dropdown {
                display: none;
                width: 0;
    
                position: absolute;
                top: 70px;
                left: 0;
                background-color: $color-white;
    
    
                ul {
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                }
    
                li {
                    padding: 2rem;
                    // font-weight: bold;
                    font-size: 1.8rem;
                    border-bottom: 1px solid #ddd;
                }
            }
    
            &__line {
    
    
                &>* {
                    background-color: rgba($color-black, 0.8);
    
                }
    
                &--1 {
                    height: 2px;
    
                }
    
                &--2 {
                    height: 1px;
                    margin: 7px 0;
    
                }
    
                &--3 {
                    height: 2px;
                }
    
    
            }
    
            /*
            &__line:hover + &__dropdown {
                
                display: block;
                width: 100vw;
                background-color: $color-white;
                
            }
            */
        }

    }

    &__dashboard {
        background-color: $color-grey-light-2;

        display: flex;
        justify-content: center;
        align-items: center;
        // width: 90vw;
        height: calc(100vh - 90px);

        @include respond(tab-land) {
            flex-direction: column;
            height: auto;
            
        }

        &__main {
            flex: 0 0 70%;

            @include respond(tab-land) {
                flex: 1;
                width: 100%;
            }
            

            &__previews {
                display: flex;

                flex-direction: column;
                padding: 2rem;

                @include respond(tab-land) {
                    width: 100%;
                }


                &__details {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    grid-column-gap: 2rem;

                    @include respond(phone-large) {
                        flex-direction: column;
                        // align-items: stretch;
                        width: 100%;
                    }

                    @include respond(phone-medium) {
                        flex-direction: column;
                        // align-items: stretch;
                        
                    }

                    &__item {
                        padding: 2rem;

                        display: flex;
                        background-color: $color-white;
                        border-radius: 1rem;

                        @include respond(phone-large) {
                            margin-right: 0;
                            margin-bottom: 2rem;
                            width: 80%;
                        }

                        @include respond(phone-large) {
                            
                            width: 90%;
                        }


                        &__imgbox {
                            margin-right: 2rem;
                        }

                        &__img {
                            width: 3.5rem;
                            height: 3.5rem;
                        }

                        &__text {

                            &__value {
                                font-weight: bold;
                                font-size: 1.4rem;
                                margin-bottom: 5px;
                            }

                            &__label {
                                font-size: 1.2rem;
                            }
                        }
                    }

                }

                &__chartsection {

                    display: flex;
                    align-items: center;
                    height: 30rem;
                    grid-column-gap: 2.5rem;
                    padding-top: 2rem;
                    font-size: 1.5rem;

                    tspan {
                        font-size: .8rem;
                    }

                    @include respond(tab-land) {
                        height: 40rem;
                    }

                    @include respond(phone-large) {
                        flex-direction: column;
                        height: 60rem;
                    }

                    &__chartbox {
                        height: 100%;
                        flex: 0 0 70%;
                        background-color: $color-white;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;

                        @include respond(phone-large) {
                            
                            height: 40rem;
                            // flex: 1;
                            width: 95%;
                        }

                    }

                    &__breakdownbox {
                        display: flex;
                        justify-content: space-between;

                        flex-direction: column;
                        flex: 1;
                        height: 90%;

                        grid-row-gap: 3rem;

                        @include respond(phone-large) {
                            flex-direction: row;
                            margin: 3rem 0;
                            width: 95%;
                        }

                        
                    }

                    &__incomebox {
                        flex: 1;
                        background-color: $color-white;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                    }

                    &__expensebox {
                        flex: 1;
                        background-color: $color-white;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                    }

                }

                &__limit {
                    display: flex;
                    grid-column-gap: 3rem;
                    margin-top: 3rem;

                    @include respond(phone-large) {
                        flex-direction: column;
                    }

                    &__container {
                        flex: 1;
                        background-color: $color-white;
                        padding: 2rem;

                        @include respond(phone-large) {
                            margin-bottom: 2rem;
                        }
                    }

                    &__head {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 1rem;

                        &__text {
                            font-weight: bold;
                            font-size: 1.4rem;
                        }

                        &__icon {
                            width: 2.5rem;
                            height: 2.5rem;
                        }

                    }

                    &__content {
                        font-weight: bold;
                        font-size: 1.6rem;
                        margin-bottom: 1rem;

                        span {
                            font-weight: normal;
                            font-size: 1.2rem;

                        }
                    }

                    &__viewbox {
                        height: 1rem;
                        background-color: $color-grey-dark;

                        &__filled {
                            width: 80%;

                            background-color: $color-primary-dark;

                            z-index: 99;
                            height: 100%;
                        }
                    }
                }
            }

        }

        &__card {
            flex: 1;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @include respond(tab-land) {
                // flex-direction: row;
                width: 80%;
            }

            &__container {
                width: 100%;

                @include respond(tab-land) {
                    margin-bottom: 5rem;
                }
            }

            &__head {
                font-weight: bold;
                font-size: 1.4rem;
                margin: 1.2rem 0;
            }

            &__item {
                background-color: $color-primary-dark;
                border-radius: 2rem;
                width: 95%;
                height: 19rem;
                color: $color-white;
                padding: 1rem;
                padding-left: 4rem;
                font-size: 1.3rem;
                box-shadow: 2px 2px 2px 2px black;

                &__head {
                    display: flex;
                    margin-top: 2rem;
                    margin-bottom: 4rem;

                    span {
                        margin-left: 1.5rem;
                        font-weight: bold;
                    }

                    &__logo {
                        width: 2rem;
                        height: 2rem;
                    }
                }

                &__name {}

                &__accountno {
                    font-weight: bold;
                    font-size: 1.6rem;
                    margin-top: 1rem;
                    margin-bottom: 1.5rem;
                }

                &__date {}

                &__btnsection {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 2rem 0;
                    flex-direction: column;

                }

                &__btn {
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    background-color: $color-secondary-light;



                    &--add {
                        background-color: $color-tertiary-dark;
                    }

                    &--block {
                        display: none;
                        width: 0;

                    }


                    &__label {
                        width: 1.5rem;
                        height: 1.5rem;
                        margin-right: 6px;
                    }

                    span {}

                }


            }

            &__transactions {
                background-color: $color-white;
                padding: 2rem;
                width: 100%;
                margin-right: 5px;
                margin-bottom: 1rem;

                @include respond(tab-land) {
                    width: 80%;
                }

                @include respond(phone-large) {
                    width: 95%;
                }

                &__head {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 1.7rem;
                    font-size: 1.4rem;

                    &__title {
                        font-weight: bold;
                        font-size: 1.5rem;
                    }

                    &__icon {
                        width: 2rem;
                        height: 2rem;
                    }
                }

                &__body {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    // justify-content: center;
                    // align-items: center;
                }

                

                &__iconbox {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 2rem 0;
                }

                &__item {

                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 1.5rem;
                    cursor: pointer;

                    &__body {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                    }

                    &__iconbox {
                        margin-right: 2rem;
                    }

                    &__icon {
                        width: 2rem;
                        height: 2rem;
                    }

                    &__details {
                        display: flex;
                        flex-direction: column;

                        &__title {
                            margin-bottom: 5px;
                            font-weight: bold;
                            font-size: 1.4rem;
                        }

                        &__date {
                            font-size: 1.3rem;
                        }

                        

                    }

                    &__price {
                        font-weight: bold;
                        font-size: 1rem;

                        p {
                            
                        }
                    }
                }

                &__fulllist {
                    display: none;
                    width: 0;
                    
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    margin-bottom: 2rem;

                    li {
                        width: 80%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        padding: 5px 1rem;

                        @include respond(tab-land) {
                            // width: 90%;
                        }

                    }

                    p {
                    font-weight: bold;
                    font-size: 1.5rem; 

                    }

                    span {
                        align-self: flex-end;
                        color: $color-secondary-dark;
                        font-size: 1.3rem;
                    }
                }



                &__btn {
                    background-color: $color-primary-dark;
                    width: 100%;

                    @include respond(tab-land) {
                        margin-top: 2rem;
                    }
                }

                &__itemcontainer {
                    width: 100%;
                }


                &__itemcontainer:hover &__fulllist {
                    display: flex;
                    width: auto;
                }

               

            }

        }

    }

    &__sidebar {
        // flex: 0 0 8vw;
        height: 100vh;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 3rem 2rem;
        background-color: $color-primary-dark;
        color: white;

        @include respond(tab-land) {
            display: none;
            width: 0;
        }

        &__iconbox {

        }

        &__icon {
            width: 3rem;
            height: 3rem;
        }

        &__list {
            list-style: none;

            display: flex;
            flex-direction: column;

            &__icon {
                margin-right: 2rem;

                width: 2.5rem;
                height: 2.5rem;

            }

            &--selected {
                background-color: $color-white;
                color: black;
            }

            li {
                display: flex;
                margin-bottom: 1rem;
                padding: 1rem 2rem;

                cursor: pointer;

                &:hover {
                    background-color: $color-white;
                    color: black;
                }

                &--selected {
                    background-color: $color-white;
                    color: black;
                }

            }

            span {
                font-weight: bold;
                font-size: 1.4rem;

            }

        }

        &__logout {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            cursor: pointer;

            &__icon {
                margin-bottom: 5px;
                width: 2.5rem;
                height: 2.5rem;

            }

            p {

            }

        }
    }

    &__maincontainer {
        display: flex;
    }

    &__contentcontainer {
        flex: 1;
        height: calc(100vh - 10rem);

        display: flex;
        flex-direction: column;
        
        

    }
}