.notifications {

    display: flex;
    width: 100vw;

    @include respond(tab-land) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
       
    }


    &__content {
        flex: 1;
        padding: $body-padding-large;

        @include respond(tab-land) {
            padding: $body-padding-medium;
        }

        @include respond(phone-large) {
            padding: $body-padding-small;
        }

        @include respond(phone-medium) {
            padding: $body-padding-medium;
        }

    }


    &__tophead {
        font-size: 1.6rem;
        padding-bottom: 2rem;

    }

    &__header {
        font-size: $header-big;
        margin-bottom: 2rem;
        width: 80%;

        @include respond(phone-large) {
            font-size: $header-medium;
        }

    }

    &__textbox {
        display: flex;
        justify-content: space-between;
        padding-bottom: 2rem;
        

    }

    &__list {
        list-style: none;

        &__item {
            margin-bottom: 1rem;

            display: flex;
            // justify-content: center;
            align-items: center;

            &__label {
                width: 3rem;
                height: 3rem;
                color: $color-primary-dark;
                margin-right: 1.5rem;
            
            }

            span {

            }

        }

        
    }

    &__seeall {

        display: flex;
        // justify-content: center;
        align-items: center;


        &__btn {

            color: $color-primary-dark;
            background-color: transparent;
            border-radius: 0;
            font-weight: 700;
            font-size: 1.8rem;

        }
    }



    &__receipt {
        flex: 1;

        &__container {

            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            align-items: center;

        }

        &__item {
            background-color: rgba($color-grey-light-1, 0.8);
            padding: 1rem 2rem;
            margin-bottom: 2rem;
            width: 80%;



            &__labeltextbox {
                display: flex;
                // justify-content: center;
                // align-items: center;

            }

            &__label {

            }

            &__img {

                width: 6rem;
                height: 6rem;
                margin-right: 2rem;

            }

            &__textbox {

                display: flex;
                flex-direction: column;
                justify-content: center;
                // align-items: center;

                &__main {
                    margin-bottom: 1rem;
                    font-weight: bold;
                }

                &__sub {

                }
            }
        }

    }
}