.footer {

    display: flex;
    //justify-content: center;
    // align-items: center;
    flex-direction: column;
    padding-top: 7rem;
    margin-bottom: 1rem;
    border-top: 1px solid rgba($color-grey-dark, 0.7);
    width: 100%;

    &__main {
        display: flex;
        justify-content: center;
        align-items: center;

        @include respond(tab-land) {
            flex-direction: column;
        }
    }

    &__sub {
        display: flex;
        justify-content: space-between;
        align-items: center;

        border-top: 1px solid rgba($color-grey-dark, 0.7);

        @include respond(tab-land) {
            flex-direction: column;
        }

        &__copyright {
            display: flex;
            justify-content: center;
            align-items: center;


            @include respond(tab-land) {
                padding: 1rem 2rem;
                text-align: center;
            }

            p {
                margin: 1rem;
                margin-right: 2rem;
                color: $color-black;
            }

        }

        &__read {
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                margin: 1rem;
                margin-right: 2rem;
                color: $color-primary-dark;
                cursor: pointer;
            }

        }



    }

    &__logobox {}

    &__logo {
        height: 100%;
        width: 10rem;
    }

    &__about {
        width: 20%;

        @include respond(tab-land) {
            width: 50%;
            margin-bottom: 5rem;
            margin-top: 5rem;
        }

        @include respond(phone-large) {
            width: 65%;
        }


    }

    &__blog {
        width: 20%;

        @include respond(tab-land) {
            width: 50%;
            margin-bottom: 5rem;
        }

        @include respond(phone-large) {
            width: 65%;
        }

    }

    &__accounts {
        
        display: none;
        width: 0;

        width: 20%;

        @include respond(tab-land) {
            width: 50%;
            margin-bottom: 5rem;
        }

        @include respond(phone-large) {
            width: 65%;
        }

    }

    &__statements {

        width: 20%;

        @include respond(tab-land) {
            width: 50%;
            margin-bottom: 5rem;
        }

        @include respond(phone-large) {
            width: 65%;
        }

    }

    &__quicklinks {
        width: 20%;

        @include respond(tab-land) {
            width: 50%;
            margin-bottom: 5rem;
        }

        @include respond(phone-large) {
            width: 65%;
        }

    }

    &__subhead {
        font-weight: bold;
        margin-bottom: 3rem;
        text-align: center;
    }

    &__subcontainer {
        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;
        list-style: none;



        li {
            margin-bottom: 2rem;
            cursor: pointer;
            transition: all .2s ease-in-out;

            &:hover {
                font-weight: bold;
                transform: translateX(-2px);
            }
        }

    }
}