.mobile {
    width: 100%;
    height: auto;
    padding: 5rem 10rem;

    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-white;
    margin-bottom: 6rem;

    @include respond(tab-land) {
            
        padding: 5rem;
    }

    @include respond(phone-large) {
            
        padding: 2rem;
    }

    &__card {
        flex: 1;
        height: 60rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 12rem 5rem 0 7rem;
        
        background-color: rgba($color-primary-dark, 0.8);
        overflow: hidden;

        @include respond(tab-land) {

            flex-direction: column;
            padding: 5rem 2rem 0 3rem;
            // overflow: auto;
            height: auto;
        }

        &__content {
            flex: 1.3;

            &__header {
                font-size: 4rem;
                margin-bottom: 2rem;
                width: 90%;

            }

            &__text {
                font-size: 1.8rem;
            }

            &__list {
                list-style: none;

                display: flex;
                flex-wrap: wrap;
                margin-top: 3rem;

                &__item {

                    margin-bottom: 1rem;

                    display: flex;
                    // justify-content: center;
                    align-items: center;
                    width: 45%;

                    @include respond(tab-land) {

                        width: 80%;
                    }

                    &__label {
                        width: 3rem;
                        height: 3rem;
                        color: $color-white;
                        margin-right: 1.5rem;

                    }

                    span {}

                }
            }

            &__imgsection {
                margin-top: 2rem;

                width: 100%;
                display: flex;
                // justify-content: center;
                align-items: center;
                margin-bottom: 4rem;

                @include respond(tab-land) {

                   flex-direction: column;
                }
            }

            &__imgbox {
                margin-right: 2rem;

                

                &:last-child {
                    margin-right: 0;
                }
            }

            &__img {
                width: auto;
                height: auto;
            }

        }

        &__imgsection {

            flex: 1;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__imgbox {
            flex: 1;

            display: flex;
            justify-content: center;
            align-items: flex-start;

            
            

            @include respond(tab-land) {
                height: 500px;
                overflow: hidden;
            }

            @include respond(phone-large) {
                height: 400px;
                
            }

            @include respond(phone-medium) {
                height: 350px;
                
            }


            
        }

        &__img {
            // height: 40rem;
            max-width: 100%;
        }
    }
}