.header {
    padding: 2rem;
    display: flex;
    width: 100vw;
    position: fixed;
    height: 90px;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 99;
    height: 100px;

    @include respond(phone-large) {
        padding: 1.8rem;
    }

    @include respond(phone-medium) {
        padding: 1rem;
    }


    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;

        position: relative;
    }

    &__logobox {
        padding-left: 1rem;
        padding-right: 1rem;
        height: 25px;

    }

    &__logo {
        height: 100%;
        width: 10rem;

    }

    &__droplinksbox {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;

        list-style: none;

        @include respond(tab-land) {
            display: none;
            width: 0;
        }

    }

    &__droplinks {
        padding: 1rem;
        cursor: pointer;
    }

    &__account {}

    &__menu {

        display: none;
        width: 0;


        @include respond(tab-land) {
            display: flex;
            flex-direction: column;
            width: 30px;
        }

        &__dropdown {
            display: none;
            width: 0;

            position: absolute;
            top: 70px;
            left: 0;
            background-color: $color-white;


            ul {
                list-style: none;
                display: flex;
                flex-direction: column;
            }

            li {
                padding: 2rem;
                // font-weight: bold;
                font-size: 1.8rem;
                border-bottom: 1px solid #ddd;
            }
        }

        &__line {


            &>* {
                background-color: rgba($color-black, 0.8);

            }

            &--1 {
                height: 2px;

            }

            &--2 {
                height: 1px;
                margin: 7px 0;

            }

            &--3 {
                height: 2px;
            }


        }

        /*
        &__line:hover + &__dropdown {
            
            display: block;
            width: 100vw;
            background-color: $color-white;
            
        }
        */
    }

    &__btn {

        &--login {
            color: $color-primary-dark;
            background-color: transparent;
            border-radius: none;

            @include respond(phone-large) {
                display: none;
                width: 0;
            }

        }

        &--open {
            background-color: $color-primary-dark;

            @include respond(phone-large) {}

        }
    }

}