.savings {

    height: auto;
    margin-bottom: 8rem;

    @include respond(tab-land) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    &__tophead {
        font-size: 1.6rem;
        padding: 1rem 10rem;
    }

    &__header {
        font-size: $header-big;
        margin-bottom: 2rem;
        width: 60%;
        padding: 1rem 10rem;
        // padding-bottom: 2rem;

        @include respond(tab-land) {
            width: 100%;
            padding: 1rem 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-size: $header-big;
            text-align: center;
        }

        @include respond(phone-large) {
            font-size: $header-medium;
        }

    }

    &__textbox {
        display: flex;
        justify-content: space-between;
        padding: 2rem 10rem;

        @include respond(tab-land) {
            flex-direction: column;
            padding: 2rem 7rem;
        }

        @include respond(phone-large) {
            flex-direction: column;
            padding: 2rem 4rem;
        }

        @include respond(phone-medium) {
            flex-direction: column;
            padding: 2rem;
        }
    }

    &__text {
        font-size: 1.8rem;
        flex: 1;
    }

    &__seeall {
        flex: 1;

        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        &__btn {

            color: $color-primary-dark;
            background-color: transparent;
            border-radius: 0;
            font-size: 1.8rem;

        }
    }

    &__cardcontainer {
        
        padding: 1rem 10rem;
        display: flex;
        flex-wrap: wrap;

        @include respond(tab-land) {
            padding: 1rem 7rem;
        }

        @include respond(phone-large) {
            padding: 1rem 4rem;
        }

        @include respond(phone-medium) {
            padding: 1rem 2rem;
        }

        

        &>* {
            margin-right: 1rem;

            @include respond(phone-large) {
                padding: 0;
            }

        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__cardbox {
        flex: 1;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

    }

    &__card {

        // height: 100%;
        border: 1px solid rgba($color-grey-dark-2, 0.8);
        padding: 5rem 0;
        width: 25rem;
        max-width: 30rem;
        display: flex;
        justify-content: center;
        align-items: center;

        @include respond(tab-land) {
            
        }



        &__img {
            width: 6rem;
            height: 6rem;
        }

        &__textbox {
            padding: 2rem 0;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 20rem;

            &__name {
                font-weight: 700;
                margin-bottom: 1rem;
            }

            &__price {
                font-weight: 200;
            }
        }
    }
}