.myrodal {
    padding: 1rem;

    h2 {
        margin-bottom: 10px;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
        height: 500px;

        overflow: scroll;

        margin: 3rem 0;
    }

    &__btn {
        background-color: $color-primary-dark;
    }
}

