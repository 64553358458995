.bloglanding {

    padding: 12vh 5rem 10rem 5rem;
    
    

    &__header {
        margin: 10rem 0;
        font-size: $header-big;

        @include respond(tab-land) {
            font-size: $header-medium;
            text-align: center;
        }
    }

    &__tags {
        display: flex;
        // justify-content: center;
        align-items: center;
        margin-bottom: 5rem;

        @include respond(tab-land) {
            flex-direction: column;
        }

        &__title {
            font-weight: 700;
            font-size: 1.8rem;
            margin-right: 3rem;

            @include respond(tab-land) {
                margin-bottom: 2rem;
            }
        }

        &__content {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__item {
            font-size: $default-font-size;
            padding: 1.5rem;
            background-color: $color-grey-light-1;
            margin-right: 2rem;
        }

    }

    &__blogs {
        width: 95vw;
        display: flex;
        // justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        grid-column-gap: 2.5rem;
    }

}