.contactus {
    padding: 3rem 1.5rem;
    width: 95%;
    display: flex;
    justify-content: space-between;
    background-color: rgba($color-primary-dark, 0.8);
    align-self: center;
    color: $color-white;
    margin-bottom: 6rem;
    border-radius: 1rem;

    @include respond(tab-land) {
        flex-direction: column;

        justify-content: center;
        align-items: center;
    }



    &__item {
        flex: 2;
        height: 100%;

        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include respond(tab-land) {
            justify-content: center;
            margin-bottom: 4rem;

            &:last-child {
                margin-bottom: 0;
            }

            width: 100%;
        }

        @include respond(phone-large) {
            flex-direction: column;
        }

        &--small {
            flex: 0.8;
            
        }

        &--medium {
            flex: 0.8;
        }

        &__subbox {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            margin-right: 1.7rem;
            width: 50%;



            &:last-child {
                margin-right: 0;
            }

            &__big {
                width: 60%;
            }

            &__small {
                width: 40%;
            }

            @include respond(tab-land) {
                width: 50%;
            }

            @include respond(phone-large) {
                width: 80%;
                margin-bottom: 2rem;
                margin-right: 0;
                justify-content: center;
            }
        }

        &__channel {

            display: flex;

            &__imgbox {
                padding: 1.2rem;
                background-color: rgba($color-primary-dark, 1);
                margin-right: 2rem;

                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3rem;

            }

            &__img {
                width: auto;
                height: 2.5rem;
                color: $color-white;
                display: block;
            }

            &__text {
                display: flex;
                flex-direction: column;

                &--main {
                    font-size: 1.6rem;
                    margin-bottom: 1.5rem;
                    font-weight: 700;

                }

                &--sub {
                    font-size: 1.4rem;
                }
            }

        }

        &__textbox {
            &__title {
                margin-bottom: 1rem;
            }
        }
    }

    &__btn {
        background-color: $color-black;
        padding: 2rem 4.5rem;
        font-weight: 700;
        border-radius: 5px;

    }
}