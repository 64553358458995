.otpdd {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    h3 {
        font-size: 2rem;
        margin: 2rem 0;
        text-align: center;
    }

    &__container {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        
        
    }

    p {
        font-weight: bold;
        // margin-bottom: 2.5rem;
        text-align: center;

        @include respond(phone-large) {
            padding: 0 2rem;
        }
    }

    &__inputbox {
        
        margin: 3rem 0;

        @include respond(phone-large) {
            width: 100%;
            padding: 1rem;
        }

        label {
            margin-bottom: 2rem;
        }
    }

    &__input {

        padding: 1rem 1.5rem;
        width: 40rem;
        font-size: 1.4rem;

        @include respond(phone-large) {
            width: 90%;
        }

        &__wrap {
            display: flex;

        }

        &__icon {
            width: 2rem;
            height: 2rem;


            &__box {

                padding: 1rem;
                border: 2px solid $color-black;


            }
        }

        &__label {
            margin-bottom: 1rem;

            p {
                span {}
            }
        }

    }

    &__btnsection {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__btn {
        background-color: $color-primary;
        width: 30rem;
    }

    &__loader {

        margin-top: 6rem;
    }
}