.reviews {
    padding: $body-padding-large;

    @include respond(tab-land) {
        padding: $body-padding-medium;
    }

    @include respond(phone-large) {
        padding: $body-padding-small;
    }

    @include respond(phone-medium) {
        padding: $body-padding-xtra-small;
    }


    &__tophead {
        font-size: 1.6rem;
        padding-bottom: 2rem;

    }

    &__headerBox {
        display: flex;
        justify-content: space-between;

        @include respond(tab-land) {
            flex-direction: column;
            
        }
    }

    &__header {
        font-size: 6rem;
        margin-bottom: 2rem;
        width: 55%;
        display: flex;

        @include respond(tab-land) {
            width: 90%;
        }

        @include respond(phone-large) {
            font-size: $header-medium;
        }

        

        

    }

    &__overallrate {
        display: flex;
        justify-content: center;
        align-items: center;

        align-self: flex-end;

        &__star {
            color: $color-primary-dark;
            width: 3rem;
            height: 3rem;
        }

        &__label {
            background-color: rgba($color-primary-dark, 0.3);
            width: 4.5rem;
            height: 4.5rem;
            border-radius: 2rem;
            margin-right: 2rem;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__text {

            span {
                color: $color-primary-dark;
            }

        }

    }

    &__container {
        flex: 1;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        align-content: center;
        padding: 2rem;
        margin-top: 4rem;

        @include respond(phone-large) {
           
            padding: 2px;
        }


    }


    &__item {
        width: 30%;
        
        margin-bottom: 2rem;
        padding: 4rem 5rem;
        border: 1px solid rgba($color-grey-dark, 0.5);
        margin-right: 3rem;

        @include respond(tab-land) {
            width: 45%;
            padding: 3rem;
        }

        @include respond(phone-large) {
            width: 90%;
            padding: 2rem;
        }
        

        &__starbox {
            padding: 1rem 0;
        }

        &__star {
            color: $color-primary-dark;
        }

        &__header {
            padding: 2rem 0;
            font-size: 2rem;
        }

        &__text {}

        &__reviewer {
            padding: 1.5rem 0;

            &__name {
                font-weight: bold;
                padding: 1rem 0;
            }

            &__job {
                font-weight: 100;
                color: $color-grey-dark;
            }
        }
    }


}