.signup {

    background-image: linear-gradient(rgba($color-secondary-light, 0.1), rgba($color-secondary-dark, 0.1)),
                    url(../img/viktor-forgacs-q8XSCZYh6D8-unsplash.jpg);
    background-size: cover;
    min-height: 100vh;
    width: 100vw;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__form {

        min-height: 100%;
        width: 100%;
        padding: 5rem;
        margin-top: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @include respond(tab-land) {
            padding: 4rem;
            padding-top: 6rem;
        }

        @include respond(phone-large) {
            padding: 2rem;
            padding-top: 4rem;
        }

        
    }

    &__inputbox {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        margin-bottom: 2rem;
        position: relative;

        @include respond(tab-land) {
            width: 70%;
        }

        @include respond(phone-large) {
            width: 95%;
        }

        @include respond(phone-medium) {
            width: 100%;
        }


    }

    &__input {
        // width: 20rem;
        padding: 1.5rem;
        font-size: 1.6rem;
        width: 100%;
        

        &__label {
            margin-left: 1rem;
            color: $color-white;
            
            width: 2rem;
            height: 2rem;
           

            &__box {
                padding: 1.5rem;
                background-color: $color-primary-dark
            }
        }

    }

    &__btnsection {

        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        margin-bottom: 3rem;

        @include respond(tab-land) {
            width: 80%;
        }

        @include respond(phone-large) {
            width: 50%;
            flex-direction: column;
        }

    }

    &__btn {
        border-radius: 1rem;

        &--1 {
            background-color: $color-primary-dark;
            margin-right: 3rem;

            @include respond(phone-large) {
                margin-right: 0;
                margin-bottom: 2rem;
            }
        }

        &--2 {
            background-color: $color-secondary-light;
        }
    }
}