.sptlanding {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: $color-primary-light;

    padding-top: 100px;
    overflow: hidden;
    margin-bottom: 8rem;

    @include respond(tab-land) {

        justify-content: center;

    }

    @include respond(phone-large) {
        flex-direction: column;
    }

    &__header {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;

        padding: 8rem 0;
        padding-left: 6rem;

        overflow: hidden;

        @include respond(tab-land) {

            overflow: auto;
            padding: 12rem 0;
            padding-left: 3rem;
        }

        &__sub {
            font-size: 1.8rem;
            margin-bottom: 2rem;
        }

        &__main {
            font-size: $header-big;
            width: 60%;

            @include respond(tab-land) {
                font-size: $header-medium;
                width: 80%;
            }
        }

    }

    &__cardbox {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        @include respond(tab-land) {}

        @include respond(phone-large) {
            margin-top: 4rem;
            width: 100vw;
            padding-bottom: 20rem;
        }
    }

    &__card {

        &__imgbox {
            position: absolute;
            top: -12rem;
            left: 10rem;

            @include respond(tab-land) {

                left: 5rem;
            }

            @include respond(phone-large) {
                height: 20rem;
            }
        }



        &__img {
            width: 400px;

            @include respond(tab-land) {
                width: 300px;
            }
        }
    }
}