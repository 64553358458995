.ftrlanding {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 6rem 0 6rem;
    background-color: $color-primary-light;
    // height: 100rem;
    overflow: hidden;

    @include respond(phone-large) {
        padding: 100px 2rem 0 2rem;
    }


    &__tophead {
        font-size: 1.6rem;
        padding-bottom: 2rem;
        padding-top: 6rem;
    }

    &__header {
        font-size: $header-big;
        margin-bottom: 2rem;
        
        text-align: center;

        @include respond(phone-large) {
            font-size: $header-medium;
        }

    }

    &__text {
        display: flex;
        justify-content: space-between;
        padding-bottom: 2rem;

        width: 60%;
        text-align: center;

        @include respond(phone-large) {
            width: 80%;
            font-size: 2rem;
        }
        
    }

    &__btnbox {
        margin-top: 4rem;

        @include respond(phone-large) {
            display: flex;
            flex-direction: column;

            justify-content: center;
            align-items: center;
        }
    }

    &__btn {

        &--open {
            background-color: $color-primary-dark;
            padding: 2rem 3rem;
            border-radius: 1rem;
            font-weight: 700;
        }

        &--compare {
            color: $color-primary-dark;
            background-color: transparent;
            border-radius: none;
            padding: 2rem 3rem;
        }
    }

    &__cardsection {
        display: flex;
        justify-content: center;
        // align-items: center;
        overflow: hidden;
        margin-top: 5rem;
        max-width: 98.5vw;
        position: relative;
        height: 40rem;
        transform: translate(0, 20%);

        @include respond(tab-land) {
            margin-top: 4rem;
            
        }

        @include respond(phone-large) {
            margin-top: -6rem;
            height: 28rem;
            transform: translate(0, 35%);
        }

    }

    &__card {
        display: inline-block;
        margin-right: 6rem;
        // max-width: 100%;

        @include respond(tab-land) {
            margin-right: 4rem;
        }

        @include respond(phone-large) {
            margin-right: 2rem;
        }
        

        &:last-child {
            margin-right: 0;
        }
        

        &--1 {
        
        }

        &--2 {}

        &--3 {}

        &--4 {}

        &--5 {
           
        }

        &__img {
            // max-width: 100%;

            @include respond(small-desktop) {
                width: 350px;
            }

            @include respond(tab-land) {
                width: 250px;
            }

            @include respond(phone-large) {
                width: 150px;
            }

            @include respond(phone-medium) {
                width: 120px;
            }
           

            &--1 {
                transform: translate(0, 60%);

                

            }

            &--2 {
                transform: translate(0, 40%);


            }

            &--3 {
               
                transform: translate(0, 20%);

            }

            &--4 {
                transform: translate(0, 40%);


            }

            &--5 {
                transform: translate(0, 60%);

            }
        }
    }

}