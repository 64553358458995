.services {

    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    position: relative;
    margin-bottom: 10rem;
    

    @include respond(tab-land) {
        flex-direction: column-reverse;
        align-items: center;

    }

    &__content {

        flex: 1.3;

        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: $body-padding-large;
        // align-items: center;
        position: relative;

        @include respond(tab-land) {
            padding: $body-padding-medium;
        }

        @include respond(phone-large) {
            padding: $body-padding-small;
        }

        @include respond(phone-medium) {
            padding: $body-padding-xtra-small;
        }

        &__header {
            font-size: $header-big;
            margin-bottom: 2rem;

            
    
            @include respond(phone-large) {
                font-size: $header-medium;
            }
        }

        &__list {
            

            display: flex;
            justify-content: center;
            align-items: center;

            flex-wrap: wrap;



            &__item {
                width: 48%;
                
                padding: 2.5rem 1.5rem;
                background-color: $color-white;
                border: 1px solid rgba($color-grey-dark, 0.8);
                margin-right: 1rem;
                margin-bottom: 1rem;

                @include respond(small-desktop) {
                    width: 95%;
                }

                @include respond(tab-land) {
                    // width: 48%;
                }

                &__labelbox {
                    width: 4rem;
                    height: 4rem;
                    
                    background-color: rgba($color-grey-dark, 0.2);
                    border-radius: 15px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                }

                &__label {
                    width: 3rem;
                    height: 3rem;

                    color: $color-primary-dark;

                }

                &__header {
                    padding: 1.4rem 0;
                    font-size: 1.8rem;
                }

                &__text {
                    font-size: 1.5rem;

                }

            }


        }

    }

    &__imgsection {

        flex: 1;

        display: flex;
        justify-content: center;
        align-items: center;

        position: sticky;
        top: 0;

        @include respond(tab-land) {
            position: relative;
        }


        &__imgbox {


            height: auto;

            
            

        }

        &__img {
            width: auto;
            height: 100%;

            @include respond(tab-land) {
                width: 40rem;
                
            }

            @include respond(phone-large) {
                width: 28rem;
                
            }
        }

    }
}