.mainfeatures {

    padding: 4rem;

    display: flex;
    width: 98vw;
    // overflow-x: hidden;
    margin-bottom: 15rem;

    @include respond(tab-land) {
        margin-bottom: 10rem;    
    }

    @include respond(phone-large) {
        margin-bottom: 5rem;    
    }

    &__laptop {
        flex: 1;
        width: 100%;
        display: flex;



        @include respond(tab-land) {
            display: none;
            width: 0;
        }

    }

    &__content {
        flex: 1;

        display: flex;
        flex-direction: column;

        margin-top: 10rem;
    }

    &__imgswipe {
        flex: 1;

        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 100%;

        position: relative;
        padding-top: 3rem;

        &__container {



            display: flex;
            // justify-content: center;
            // align-items: center;
            // display: inline-block;
            padding: 1rem;
            border: 1rem solid $color-black;
            border-radius: 5rem;
            position: sticky;
            top: 0;
            overflow: hidden;
        }

        &__img {
            width: auto;
            height: 85vh;
            object-fit: contain;

            // transition: all .2s linear;

            &--1 {}

            &--2 {
                position: absolute;
                top: 1rem;
                right: 1rem;

                transform: translateX(320px);

            }

            &--3 {


                position: absolute;
                top: 1rem;
                right: 1rem;

                transform: translateX(320px);

            }

            &--4 {



                position: absolute;
                top: 1rem;
                right: 1rem;

                transform: translateX(320px);

            }
        }
    }

    &__feature {
        // margin: 10rem 0;
        height: 100vh;
        padding: 10rem 0 10rem 10rem;

        display: flex;
        flex-direction: column;
        // justify-content: center;
        // align-items: center;

        &__tophead {
            font-size: 1.6rem;
            padding-bottom: 2rem;

        }

        &__header {
            font-size: $header-big;
            margin-bottom: 2rem;

            width: 80%;
        }

        &__text {
            display: flex;
            justify-content: space-between;
            padding-bottom: 2rem;

        }



        &__list {
            list-style: none;

            &__label {
                color: $color-primary-dark;
                width: 3rem;
                height: 3rem;
                margin-right: 2rem;
            }

            li {
                margin-bottom: 1rem;

                display: flex;
                justify-content: flex-start;
                align-items: center;

            }
        }

    }

    &__phone {

        display: none;
        width: 0;

        @include respond(tab-land) {
            width: 98vw;
            display: flex;

            flex-direction: column;
            flex: 1;
        }

        &__feature {
            // margin: 10rem 0;

            padding: 5rem 2rem;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            

            &__imgswipe {

                margin-bottom: 3rem;
                padding: 1rem;
                border: 1rem solid $color-black;
                border-radius: 5rem;

                &__img {

                    width: 50vw;
                    height: auto;

                    @include respond(phone-large) {
                        width: 65vw;
                    }

                }
            }

            &__tophead {
                font-size: 1.8rem;
                padding-bottom: 1.5rem;


            }

            &__header {
                font-size: calc($header-medium - .8rem);
                margin-bottom: 2rem;

                width: 95%;
                text-align: center;
            }

            &__text {
                display: flex;
                justify-content: space-between;
                padding-bottom: 2rem;

            }



            &__list {
                list-style: none;

                &__label {
                    color: $color-primary-dark;
                    width: 3rem;
                    height: 3rem;
                    margin-right: 2rem;
                }

                li {
                    margin-bottom: 1rem;

                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                }
            }

        }

    }


}