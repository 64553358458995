.cmplanding {

    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: $color-primary-light;
    // height: 70vh;
    padding-top: calc(100px);

    overflow: hidden;

    @include respond(tab-land) {

        justify-content: center;
       
    }

    @include respond(phone-large) {
        flex-direction: column;
    }

    &__header {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;


        padding: 10rem 0;
        padding-left: 6rem;

        overflow: hidden;

        @include respond(tab-land) {

            overflow: auto;
        }

        &__sub {
            font-size: 1.8rem;
            margin-bottom: 2rem;
        }

        &__main {
            font-size: $header-big;
            width: 70%;

            @include respond(tab-land) {
                font-size: $header-medium;
                width: 80%;
            }
        }

    }

    &__cardbox {

        flex: 1;
        // height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        @include respond(tab-land) {}

        @include respond(phone-large) {
            width: 100vw;
        }

        &__imgcontainer {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            @include respond(phone-large) {
                height: 20rem;
                width: 100%;


            }
        }

    }

    &__card {

        &__imgbox {
            position: absolute;
            top: -5rem;
            

            @include respond(tab-land) {
                top: 0rem;
                
            }

            @include respond(phone-large) {
                
                top: 50%;
                left: 27%;

                // transform: translate(-50%, -50%);

                
            }


            &--1 {

                z-index: 29;
                top: -10rem;
                

                @include respond(tab-land) {
                    top: -5rem;
                    
                }

                @include respond(phone-large) {
                    top: 0;
                    
                }
            }

            &--2 {
                transform: rotateZ(14deg);

                z-index: 19;
                transform-origin: 100% 100%;
            }

            &--3 {
                transform: rotateZ(-14deg);
                z-index: 9;
                transform-origin: 0% 100%;
            }

        }

        &__img {
            width: 275px;

            @include respond(tab-land) {
                width: 175px;
            }

        }

    }

}