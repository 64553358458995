.landingblog {
    padding: 5rem 7rem;
    margin-bottom: 5rem;

    @include respond(tab-land) {
        padding: 5rem;
    }

    @include respond(phone-large) {
        padding: $body-padding-small;
    }

    @include respond(phone-medium) {
        padding: $body-padding-xtra-small;
    }

    

    &__headerbox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        
    }

    &__header {
        font-size: 6rem;
        margin-bottom: 2rem;
        width: 80%;

        @include respond(phone-large) {
            font-size: $header-medium;
        }

        
    }

    &__seeall {

        &__btn {
            color: $color-primary-dark;
            background-color: transparent;
            border-radius: 0;
            font-weight: 700;
            font-size: 1.8rem;
        }
    }

    &__content {

        display: flex;
        // align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        
        padding-top: 2rem;

        @include respond(phone-large) {
            

            justify-content: center;
            align-items: center;
        }
        

        &__item {
            display: flex;
            flex-direction: column;
            margin-bottom: 2rem;
            width: 30%;

            @include respond(tab-land) {
                width: 45%;
            }

            @include respond(phone-large) {
                width: 85%;

                justify-content: center;
                align-items: center;
            }

            &__imgbox {
                width: 99%;
                height: 35rem;
                object-fit: contain;
                margin-bottom: 1rem;
                border-radius: 1rem;
                overflow: hidden;

            }

            &__img {
                width: 100%;
                height: 100%;
            }

            &__title {
                font-size: 2.5rem;
                margin-bottom: 1rem;

            }

            &__date {
                font-size: 1.4rem;
                color: $color-primary-dark;
                padding: 1rem 1rem;
                
                font-weight: bold;
            }

            &__text {
                height: 8rem;

            }

            
            &__tags {
                width: 100%;
                padding: 1rem 1rem;
                list-style: none;
                display: flex;

                li {
                    padding: 1rem;
                    margin-right: 1rem;
                    background-color: rgba($color-grey-light-1, 0.7);
                    color: $color-grey-dark;

                }

                &:last-child {
                    margin-right: 0;
                }

                
            }

        }
    }
}