.instantmoney {

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color-primary-dark, 0.17);
    margin-bottom: 6rem;
    padding: 4rem 0;

    @include respond(tab-land) {
        flex-direction: column;
        align-items: center;

    }


    &__content {
        flex: 1;
        padding: $body-padding-large;


        @include respond(tab-land) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: $body-padding-medium;
        }

        @include respond(phone-large) {
            padding: $body-padding-small;
        }

        @include respond(phone-small) {
            padding: $body-padding-xtra-small;
        }


        &__header {
            font-size: $header-big;
            margin-bottom: 2rem;
            width: 80%;

            

            @include respond(phone-large) {
                font-size: $header-medium;
                width: 90%;
            }

        }

        &__text {
            font-size: 1.8rem;
            margin-bottom: 3rem;

            @include respond(tab-land) {
                text-align: center;
            }

            &__list {

                list-style: none;
                

                &__item {
                    display: flex;
                    // justify-content: center;
                    align-items: center;
                    padding: 1.5rem;
                    font-size: 1.7rem;



                    &__bullet {
                        height: 2rem;
                        width: auto;
                        color: $color-primary-dark;
                        margin-right: 1rem;
                    }

                }

            }
        }

    }

    &__receipt {
        flex: 1;

        @include respond(tab-land) {
            width: 100vw;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

        }




        &__container {
            padding: 0 3rem;

            @include respond(tab-land) {
                width: 60%;
            }

            @include respond(phone-large) {
                width: 85%;
            }

            @include respond(phone-medium) {
                width: 95%;
            }

        }


        &__item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // width: 75%;
            margin-bottom: 2rem;
            background-color: $color-white;
            padding: 2rem 3.5rem;

            &__labeltextbox {
                display: flex;

            }

            &__label {
                height: 5rem;
                width: 5rem;

                display: flex;

                background-color: $color-primary-dark;
                justify-content: center;
                align-items: center;
                border-radius: 1rem;

                margin-right: 2rem;

            }

            &__textbox {

                &__main {}

                &__sub {}

            }

            &__price {

                &__value {}
            }

            &__img {
                height: 3rem;
                width: 3rem;
                color: $color-white;

            }

        }



    }
}