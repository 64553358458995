.landing {
    margin-top: 100px;
    

    &__container {
        display: flex;
        width: 95vw;
        margin-top: 10rem;
        margin-bottom: 10rem;

        @include respond(tab-land) {
            flex-direction: column-reverse;
        }
    }

    &__intro {
        flex: 1;
        padding: $body-padding-large;

        @include respond (tab-land) {
            padding: $body-padding-medium;
        }

        @include respond (phone-large) {
            padding: $body-padding-small;
        }

        @include respond (phone-medium) {
            padding: $body-padding-xtra-small;
        }

        &__header {
            font-size: $header-big;
            margin-bottom: 3rem;

            @include respond (phone-large) {
                font-size: $header-medium;
            }
        }

        &__text {
            font-size: 2rem;
            
            @include respond (tab-land) {
                text-align: center;
            }

        }

        &__services {

            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 3rem 0;


            &__item {
                width: 45%;
                min-width: 200px;
                display: flex;
                padding-bottom: 1.5rem;

                &__label {
                    color: $color-primary-dark;
                    margin-right: 8px;
                    height: 2rem;
                    width: auto;

                }

                &__bullet {}
            }
        }

        &__btnbox {
            margin-top: 3rem;

            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

        }

        &__open {
            background-color: $color-primary-dark;
            padding: 2rem 3rem;
            border-radius: 1rem;
            font-weight: 700;
        }

        &__compare {
            color: $color-primary-dark;
            background-color: transparent;
            border-radius: none;
            padding: 2rem 3rem;
        }


    }

    &__cardbox {}

    &__card {
        flex: 1;
        width: 100%;
        display: flex;
        justify-items: center;
        align-items: center;
        
        @include respond(tab-land) {
            margin: 200px 0;
            
        }

        &__container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2rem 5rem;
            width: 100%;

            position: relative;
        }

        &__item {
            height: 40rem;
            width: 40rem;




            &--black {

                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

            }

            &--green {

                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-47%, -50%) rotateZ(20deg);

            }

        }



    }


}