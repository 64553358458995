.onebank {

    padding: 4rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;

    @include respond(phone-large) {
        padding: 2rem;
    }

    @include respond(phone-medium) {
        padding: 1rem;
    }

    &__header {
        font-size: $header-big;
        margin-bottom: 2rem;
        text-align: center;

        @include respond(phone-large) {
            font-size: $header-medium;
        }
    }

    &__text {
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8rem;



        @include respond(phone-large) {
            width: 80%;
            font-size: 2rem;
        }

    }

    &__container {
        padding: 2rem 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        @include respond(phone-large) {
            padding: 2rem 1rem;
        }
    }

    &__item {
        background-color: $color-grey-light-1;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 3rem;
        margin-right: 2rem;
        padding-top: 4rem;
        overflow: hidden;

        &:nth-child(2),
        &:nth-child(5) {
            margin-right: 0;
        }

        @include respond(tab-land) {
            margin-right: 0;
        }

        &--big {
            width: 46%;

            @include respond(tab-land) {
                width: 90%;
            }

            @include respond(phone-large) {
                width: 95%;
            }
        }

        &--small {
            width: 32%;

            @include respond(tab-land) {
                width: 40%;
            }

            @include respond(phone-large) {
                width: 95%;
            }

        }

        &__title {
            font-size: 3rem;
            width: 50%;

        }

        &__text {
            color: $color-grey-dark;
            width: 80%;
            margin: 2rem 0;
            text-align: center;

        }

        &__imgcontainer {
            display: flex;
            justify-content: center;
            align-items: center;


            &--big {
                overflow: hidden;

                // height: 30rem;

                width: 70%;

                align-items: flex-start;
                margin-bottom: -4rem;

                border: 10px solid $color-black;
                border-radius: 4rem;


            }

            &--small {

                grid-column-gap: 2rem;
                margin-left: -4rem;
                margin-bottom: -4rem;
                margin-right: -4rem;

                overflow: hidden;
            }

            &__card {
                /*
                @include respond(tab-land) {
                    height: 250px;
                }
                */
            }

        }

        &__imgbox {
            overflow: hidden;
            height: 250px;

            &--normal {
                width: 100%;
                height: 50%;
            }

            &--big {}

            &--small {


                &--1 {
                    padding-top: 4rem;


                }

                &--2 {}

                &--3 {
                    padding-top: 4rem;
                }
            }

        }

        &__img {

            &--big {

                height: auto;
                width: 100%;
                max-width: 100%;
            }

            &--normal {
                height: 100%;
                width: 100%;

                @include respond(tab-land) {
                    width: 40%;
                }

                @include respond(phone-large) {
                    width: 95%;
                }

            }

            &--small {

                max-width: 100%;

                

                

                &--1 {
                    width: 100%;

                }

                &--2 {
                    width: 100%;

                }

                &--3 {
                    width: 100%;

                }

                @include respond(phone-large) {
                    width: 400px;
                    height: 300px;
                }
            }



        }
    }
}