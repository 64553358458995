.section-transfer {
    width: 100%;

    //  background-image: linear-gradient(to right bottom, $color-primary, $color-primary-dark);

    @include respond(tab-port) {
        // padding: 10rem 0;
    }

    @include respond(phone-large) {
        // padding: 8rem 0;
    }

}

.bill-text {
    margin-top: -5rem;
    margin-bottom: 2rem;
    font-size: 1.8rem;
    color: #d4223e;
    width: 80%;
    text-align: center;
    font-weight: 500;
    text-transform: capitalize;

    a {
        color: #457bdb;
        font-weight: 600;
    }

    @include respond(tab-port) {
        margin-top: -3rem;
        font-size: 1.5rem;
        width: 90%;
    }

    @include respond(phone-large) {
        width: 95%;
    }
}

.transfer__book {
    height: calc(100vh - 8.2rem);

    background-image: linear-gradient(105deg,
            rgba($color-black, .4) 0%,
            rgba($color-black, .4) 50%,
            transparent 50%),
        url(../img/bank2.jpg);
    background-size: cover;
    border-radius: 3px;
    box-shadow: 0 1.5rem 4rem rgba(#000, .2);

    &--2 {
        background-image: linear-gradient(105deg,
                rgba(#eee, .9) 0%,
                rgba(#eee, .9) 50%,
                transparent 50%),
            url(../img/bank2.jpg);
    }

    @include respond(tab-land) {
        height: auto;
        
        background-image: linear-gradient(105deg,
                rgba(#000080, .9) 0%,
                rgba(#062a78, .9) 70%,
                transparent 70%),
            url(../img/bank2.jpg);
        background-size: cover;

        &--2 {
            background-image: linear-gradient(105deg,
                    rgba(#eee, .9) 0%,
                    rgba(#eee, .9) 70%,
                    transparent 70%),
                url(../img/bank2.jpg);
        }
    }

    @include respond(phone-large) {
        background-image: linear-gradient(to right,
                rgba(#000080, .8) 0%,
                rgba(#062a78, .8) 100%),
            url(../img/bank2.jpg);

        &--2 {
            background-image: linear-gradient(105deg,
                    rgba(#eee, .8) 0%,
                    rgba(#eee, .8) 100%);
        }
    }


    &__form {
        width: 100%;
        height: 100%;
        padding: 5rem;

        @include respond(tab-land) {
            width: 100%;
            // padding: 3rem;

        }

        @include respond(tab-port) {
            width: 100%;
        }

        @include respond(phone-large) {
            padding: 3rem;
        }
    }



}

.transfer__form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 5rem;

    &__group {
        width: 46%;

        @include respond(phone-large) {
            width: 100%;
        }
    }

    &__group:not(:last-child) {
        margin-bottom: 2rem;

    }

    &__input {
        font-size: 1.5rem;
        font-family: inherit;
        color: inherit;
        padding: 1.5rem 2rem;
        border-radius: 2px;
        background-color: rbga(#fff, .5);
        border: none;
        border-bottom: 3px solid transparent;
        width: 95%;
        display: block;
        transition: all .3s;

        @include respond(phone-large) {
            width: 100%;
        }



        &:focus {
            outline: none;
            box-shadow: 0 1rem 2rem rgba(#000, .1);
            border-bottom: 3px solid $color-primary;
        }

        &:focus:invalid {
            border-bottom: 3px solid #e34412;
        }

        &::-webkit-input-placeholder {
            color: #666;
            font-weight: 600;
        }
    }

    &__btnbox {
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 4rem;

    }

    &__btn {
        background-color: $color-primary-dark;
        font-weight: bold;
        width: 45%;
        padding: 1.6rem 2.5rem;

        @include respond(phone-large) {
            width: 80%;
            // padding: 2rem 3rem;
        }

    }


    &__label {
        font-size: 1.2rem;
        font-weight: 700;
        margin-left: 2rem;
        margin-top: .7rem;
        display: block;
        color: white;
        transition: all .3s;
    }

    &__input:placeholder-shown+&__label {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-4rem);
    }



}

.transfer-heading {
    font-size: 2.5rem;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
    background-image: linear-gradient(to right, $color-primary, $color-primary-dark);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: .2rem;
    transition: all .2s;
    margin-bottom: 1.2rem;
    margin-bottom: 3rem;


    &:hover {
        //  transform: skewY(2deg) skewX(15deg) scale(1.1);
        text-shadow: .5rem 1rem 2rem rgba(#000, .2);
    }

    @include respond(tab-land) {
        font-size: 2rem;
        letter-spacing: .1rem;
        margin-bottom: 1rem;
    }
}

.image__input__label {
    color: #000;
    font-weight: bold;
    font-size: 1.6rem;
}

.imageInputHeader {
    font-size: 1.7rem;
    color: #e34412;
    text-align: center;
    margin-bottom: 2.5rem;

}